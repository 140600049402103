
.dotsBannerSlider{bottom: 2vw; gap:0.3vw;}
.bg-white-50{background: rgb(255, 255, 255, 0.1); width: 1vw;  height: 1vw; transition: 0.3s;}
.dotsBannerSlider button.bg-white-50{ width: 1vw; height: 1vw; transition: 0.3s; -webkit-transition: 0.3s; -moz-transition: 0.3s; -ms-transition: 0.3s; -o-transition: 0.3s; }
.dotsBannerSlider button.bg-white{width:3vw;  height: 1vw; transition: 0.3s;}

.WhyJoinbg h3{font-size: 1.7vw; color: #1E1E1E; position: relative;}
.WhyJoinbg h3:after{content: "";
    width: 10vw;
    height: 0.2vw;
    background: #BF8500;
    display: inline-block;}

.WhyJoinbg p{font-size: 18; color: #707070;}
.font64vw{font-size: 64 !important;} 
.font48vw{font-size:48px !important;} 
.font20vw{font-size: 20px !important;} 
.font24vw{font-size: 24px !important;} 
.font04vw{font-size: 18px !important;} 
.font08vw{font-size: 16px !important;} 

.WhyJoinbg .text{width: 40vw;}
.WhyJoinbg .img{width: 42.7vw;}
.WhyJoinbg .img img {
    width: 42.7vw;
}


.imgTextbg{    width: 34.7vw; border-radius: 1vw;
    height:29vw;
    padding: 3.5vw 3.57vw; 
    }
    .imgTextGold{background: rgba(191, 133, 0, 0.10);}
    .imgTextDiamond{background: rgba(134, 29, 29, 0.10);}

    .imgTextbg h4{color: #1E1E1E; font-size: 1.25vw; }
    .imgTextbg p{color: #1E1E1E; font-size: 1vw; }
    .imgTextbg button{ color: #fff; font-size: 1vw; border: none; padding: 0.7vw 4vw; width: 100%; border-radius:0.2vw ; -webkit-border-radius:0.2vw ; -moz-border-radius:0.2vw ; -ms-border-radius:0.2vw ; -o-border-radius:0.2vw ; }
    .imgTextGold button{ background: #916008;}
    .imgTextDiamond button{ background: #861d1d;}
    .imgText{gap: 0.5vw;}
    .imgTextplanBg{gap: 2vw;}

    .imgTextbg .img img {
        width: 5.1vw;
    }

    .AboutUsNewLuxury{gap: 6vw;}


.WhatYouGet{display: flex; align-items: center; flex-direction: column; gap: 2.8vw;}
    .textAccessimgbg{gap: 2vw; text-align: center;}

    .textAccessimg{gap: 1.4vw;
        display: flex;
        justify-content: start;
        flex-direction: column; border: 0.01vw solid #ccc; padding: 2.6vw 5vw; border-radius:10px ; -webkit-border-radius:10px ; -moz-border-radius:10px ; -ms-border-radius:10px ; -o-border-radius:10px ;   }

    .textAccessimg h4{color: rgba(30, 30, 30, 1);} 
    .textAccessimg img {
        width: 4.5vw;
    }


    .MembershipCriteriabg{gap: 5vw;}
    .MembershipCriteriabg ul{padding-left: 2vw; margin-bottom: 0;}
    .MembershipCriteriabg ul li{list-style: none; padding: 0.5vw 0; display: flex; align-items: center; gap: 1vw;}
    .Requirementstext{padding-left: 10vw;}

    .yournetworkbg{background: url("../images/your-network-is-your-net-worth-elevate-yours.png") no-repeat center top fixed; background-size: cover; padding:10vw 0; }
     .yournetworkbg h2{margin: 0; padding: 0;  text-align: center;}



     .clintesliderbg{ border: 0.01vw solid #ccc; padding: 3vw 3.5vw; border-radius:0.5vw ; -webkit-border-radius:0.5vw ; -moz-border-radius:0.5vw ; -ms-border-radius:0.5vw ; -o-border-radius:0.5vw ; }
     .clintesliderbg .clientReviewtext{color: #081726; font-size: 2.1vw; padding: 1vw 0 2vw 0;}
      .clintesliderbg .clientimg{
      overflow: hidden;
      border-radius: 50vw;
      display: flex  ;
      justify-content: center;
      align-items: center;
      width: 3.855vw;
      height: 3.855vw;
      -webkit-border-radius: 50vw;
      -moz-border-radius: 50vw;
      -ms-border-radius: 50vw;
      -o-border-radius: 50vw;
}
      .clintesliderbg .clientimg img{ width: 100%; height: 100%; object-fit: cover; border-radius:50vw ; -webkit-border-radius:50vw ; -moz-border-radius:50vw ; -ms-border-radius:50vw ; -o-border-radius:50vw ; }
      .userDtails{font-size: 1vw; color: #081726; gap: 1vw;}

      .whatOurMembers{width: 22vw;}
      .clintesliderbg {
        width: 43vw;
    }

    .btnDslider{ width: 2.3vw; height: 2.3vw; background: #fff; border: 0.01vw solid rgba(218, 218, 218, 1); border-radius:50vw ; -webkit-border-radius:50vw ; -moz-border-radius:50vw ; -ms-border-radius:50vw ; -o-border-radius:50vw ; }

    .btnDsliderbg{gap: 1vw; margin-top: 2vw;}

    .EliteFeaturesbg{gap: 1vw; }
    .EliteFeatures{ width: 20vw; height: 20vw; border: 0.01vw solid #707070; border-radius:50vw ; -webkit-border-radius:100% ; -moz-border-radius:50vw ; -ms-border-radius:50vw ; -o-border-radius:50vw ; padding: 3vw 2vw; transition: 0.3s; -webkit-transition: 0.3s; -moz-transition: 0.3s; -ms-transition: 0.3s; -o-transition: 0.3s; }
    .EliteFeatures h3{padding: 0.7vw 0;}
.EliteFeatures .img{display: inline-block;}
.EliteFeatures .img img{width:3vw;}

.EliteFeatures:hover{box-shadow:0 0 0 0.2vw #861D1D, 0 0.3vw 1.5vw rgb(137 138 139 / 70%);}


.ApplyforMembership .img{width: 45.92vw;}
.ApplyforMembership .img img{width: 45.92vw;}
.ApplyforMembership .text{width: 48vw;}


.footerBgNew1,.FooterNewlogobg{gap: 2vw;}
.FooterNewlogo img{ width: 8.021vw;}
.footerTextNew{width: 25vw;}
.linksFooterNew ul{gap: 3vw; padding: 0; margin-bottom: 2vw;}
.linksFooterNew ul li{list-style: none; font-size: 1vw;}
.linksFooterNew ul li a{list-style: none; font-size: 1vw; color: #000; text-decoration: none;  display: inline-block;}

/* about us  */
.aboutusTextNew{width: 70vw;}
.aboutusTextNewbg img{margin-top: 4vw;}
/* .aboutusTextNewbg .img{} */
.aboutusTextNewbg img { 
    width: 88.96vw;
}

.HowItWorksbgNew{grid-template-columns: repeat(3, 1fr); gap: 5vw;}

.HowItWorkstextNew h3{gap: 0.5vw; padding-bottom:1vw;}
.HowItWorkstextNew .count{ width: 1.5vw; display: flex; justify-content: center; align-items: center;  height: 1.5vw; border-radius:100% ; background: #861d1d; color: #fff; font-size: 0.8vw; font-weight: normal;}

.ourvisiontextNew{background-color: #1E1E1E; padding:3.5vw 5.5vw; position: relative;}
.ourvisiontextNew::before{    content: "";
    position: absolute;
    left: -0.44vw;
    top: 0.37vw;
    z-index: -1;
    width: 2vw;
    height: 1.5vw;
    background-color: #A6A6A6;
    display: inline-block;
    transform: rotate(28deg);}
.ourvisiontextNew .text{width: 60vw;}
.ourvisiontextNew .img img{width: 13.83vw; }

.Ourmemberstext{grid-template-columns: repeat(4, 1fr); gap: 1.88vw;}
.OurtextN1{gap: 2vw;}
.OurtextN1 span.img { 
    display: inline-block;
    width: 20.625vw;
}
.OurtextN1 span.img img{width: 20.625vw;}

.aboutusnEwv1 .MuiPaper-root{background:rgba(255, 255, 255, 1) !important;}
.aboutusnEwv1 .MuiButton-root{color: #000 !important;}
.aboutusnEwv1 header{border-bottom:0.01vw solid #ccc;}
.aboutusnEwv1 .MuiButtonBase-root .css-3kkvpo-MuiButtonBase-root-MuiButton-root{color: #fff !important;}

.aboutusnEwv1 .logo-white{display: none;}
.aboutusnEwv1 .logo-color{display: block;}
.aboutusnEwv1 .MuiButtonBase-root .logo-color{display: none;}
.aboutusnEwv1 .MuiButtonBase-root .logo-white{display: block;}

.textRedLik{ color: #861D1D;}



.contactbgText{gap: 1vw; margin-top: 3vw;}
.contactLeftText{ padding: 4vw; width: 43.5vw; border-radius:1vw ; -webkit-border-radius:1vw ; -moz-border-radius:1vw ; -ms-border-radius:1vw ; -o-border-radius:1vw ; }
.contactLeftText .text h4{padding:0 0 1vw 0vw;}
.contactLeftText .text p{padding:0 0 0.5vw 0vw;}
.contactLeftText .text.singal{padding: 1.2vw 0;}
.twogridContactus {gap: 3vw; padding: 1.2vw 0; border-top: 0.01vw solid #ccc;}

.contactusImg{ position: relative; width: 43.5vw; overflow: hidden; border-radius:1vw ; -webkit-border-radius:1vw ; -moz-border-radius:1vw ; -ms-border-radius:1vw ; -o-border-radius:1vw ; }
.contactusImg img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.CFcontactusImg{ padding: 3vw; overflow: hidden; border: 0.01vw solid #ccc; border-radius:1vw ; -webkit-border-radius:1vw ; -moz-border-radius:1vw ; -ms-border-radius:1vw ; -o-border-radius:1vw ; }
/* .StillNeedbg{  } */
.StillNeedbg input,.StillNeedbg select {
    font-size: 0.9vw !important;
    border-radius: 0.2vw !important;
    height: 2.969vw; padding:0 0.8vw;
}
.StillNeedbg label{font-size: 0.8vw; margin-bottom: 0.5vw;}
.StillNeedbg textarea {
    font-size: 0.9vw !important;
    border-radius: 0.2vw !important;
    height: 4.969vw;
}
.StillNeedbg p{padding-bottom: 1vw;}

.AddBtnAttachment{ font-size: 1vw; color: #861D1D; border: 0.01vw solid #ccc; background-color: #fff; padding: 0.6vw 3vw; border-radius:0.3vw; -webkit-border-radius:0.3vw; -moz-border-radius:0.3vw; -ms-border-radius:0.3vw; -o-border-radius:0.3vw; }

.btnSubmitConts{width:100%; height: 3vw!important; font-size: 1vw !important; font-weight: bold; background: #861D1D!important; color: #fff!important;}
.gapsetform{gap: 1vw 0;}



/* Terms and Privacy */
.Terms-privCont{gap: 2vw;}
.hdtoptermpriv h2{margin-bottom: 0.8vw;}
.hdtoptermpriv p{margin-bottom: 2vw;}
.sidebar-TermsPrivacy {
    top: 10vh;
}
.sidebar-TermsPrivacy ul{padding-left: 0;
    height: calc(100vh - 21vh);
    overflow: auto;
    margin-bottom: 0;}
.sidebar-TermsPrivacy ul li{list-style: none; }
.sidebar-TermsPrivacy ul li a{text-decoration: none; font-size: 1.07vw; font-weight: 500; color: #000; padding: 0.6vw 0; display: inline-block;}
.sidebar-TermsPrivacy ul li a.active{text-decoration: none; font-size: 1.07vw; font-weight: 500; color: #861D1D;}

.TermsPrivacy-content h2{color: #861D1D; text-transform: uppercase; font-size: 1.07vw !important; font-weight: bold; margin-bottom: 0.8vw; margin-top: 2vw;}
.TermsPrivacy-content a{color: #861D1D; font-size: 0.83vw;}
.TermsPrivacy-content p{font-size: 0.83vw; color: #1E1E1E; text-align: justify; margin-bottom: 0.7vw; }
.TermsPrivacy-content ul li{font-size: 0.83vw; color: #1E1E1E;}
.TermsPrivacy-content ol li{font-size: 0.83vw; color: #1E1E1E;}


.planNewSetup {
    width: 60vw;
    margin: 0 auto;
}
 .subscription-container .planNewSetup .gold::after {
    content: "";
    position: absolute;
    top: 6px;
    right: 6px;
    bottom: 0;
    left: 6px;
    background: rgb(255 255 255);}

    .subscription-container .planNewSetup .gold::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(140deg, #BF8500, #fff 50%);}



/* Banner text start */
.bnrfontsizeSet{ top: 50%; left: 0; transform: translateY(-50%); width: 50vw; padding:0 5.5vw; }
.bnrfontsizeSet h1{ font-size: 3.35vw;
    margin-bottom: 0.7vw; }
.bnrfontsizeSet p{font-size: 1.04vw;
    padding-bottom: 1vw;
    margin-bottom: 0px; }
.btnSetWth{background: rgb(134, 29, 29);
    color: rgb(255, 255, 255);
    font-size: 1vw;
    padding: 0.7vw 3.5vw;
    border: none;
    border-radius: 50vw;}
    .bannerImgNw001{z-index: 2;} 
    .position-absolute.text-white.text-start.bnrfontsizeSet {
        z-index: 2;
    }
.bannerImgNw001::before {
  content: "";
  background: rgb(0 0 0 / 60%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.dotsBannerSlider {z-index: 2;}

    .ApplyNowBntSt{background:#fff; color: #000; font-size:1vw; padding: 0.7vw 3vw; border:none; border-radius:50vw;}
    .ApplyNowBntSt2{background:#861D1D; color: #fff; font-size:1vw; padding: 0.7vw 3vw; border:none; border-radius:50vw;} 
    /* Faq's start css */
.paraTextAccoding{margin: 0 0 0.7vw 0; font-size:0.84vw; color: #1E1E1E;}
.MobileVewBtns{display: none;}
.MobileVewBtns button{ border: 1px solid #ccc; padding: 10px 20px; font-size: 18px; font-weight: 500; border-radius:50px ; background: #f2f2f2; color: #000; }
.MobileVewBtns button.active{border: 1px solid #c18907; background: #c18907; color: #fff; }
.MobileVewBtns button.Diamond_membership.active {
    border: 1px solid #861D1D;
    background: #861D1D;
    color: #fff;
}
.planNewSetupN002 .diamond p.price {
    color: #861d1d;
}
.planNewSetupN002 .diamond p.price span {
    color: #000;
}
.planNewSetupN002 .gold  h3 {
    color: #916008;
}
/* .opastyset{} */

        /* Home responsive Css Start */
        @media (max-width:1199px){
            .bnrfontsizeSet{  width: 70vw; }
            .bnrfontsizeSet h1{ font-size: 5.35vw; }
            .bnrfontsizeSet p{font-size: 1.54vw;}
            .btnSetWth{background: rgb(134, 29, 29);
                color: rgb(255, 255, 255);
                font-size: 1.7vw; }
            .WhyJoinbg p {
                font-size: 1.34vw;}
                .WhyJoinbg h3 {
                    font-size: 2.5vw;}
                    .font48vw {
                        font-size: 3.5vw !important;
                    }
                    .font20vw {
                        font-size: 1.45vw !important;
                    }
                    .imgTextbg h4 {
                        color: #1E1E1E;
                        font-size: 1.65vw;
                    }
                    .imgTextbg p {
                        color: #1E1E1E;
                        font-size: 1.3vw;
                    }
                    .imgTextbg { 
                        height: 34vw;}
                        .font24vw {
                            font-size: 1.65vw !important;
                        }
                        .font04vw {
                            font-size: 1.34vw !important;
                        }
                        .hdtoptermpriv .font08vw {
                            font-size: 1.4vw !important;
                        }
                        .font-1vw {
                            font-size: 1.5vw;
                        }
                        .btnDslider {
                            width: 4.3vw;
                            height: 4.3vw;}

                        .EliteFeatures {
                            width: 22vw;
                            height: 22vw; 
                            padding: 1vw 2vw;}
                            .EliteFeatures .font08vw {
                                font-size: 1.05vw !important;
                            }
                            .linksFooterNew ul li a { 
                                font-size: 1.3vw;}
                                .copyrightNewone1.font08vw {
                                    font-size: 1.2vw !important;
                                }

                                .TermsPrivacy-content p,.TermsPrivacy-content ul li,.TermsPrivacy-content ol li, .TermsPrivacy-content a {
                                    font-size: 1.3vw;}

                                    .paraHeadAccoding{font-size: 1.7vw !important; }
                                    .paraTextAccoding { 
                                        font-size: 1.3vw; 
                                    }
                                    .paraHeadAccoding .count {
                                        
                                        width: 2.83vw !important;
                                        height: 2.83vw !important;
                                        font-size: 1.3vw !important;}

                                        .HowItWorkstextNew .font08vw {
                                            font-size: 1.3vw !important;
                                        }

                                        .contactLeftText .font08vw {
                                            font-size: 1.1vw !important;
                                        }
                                        .aboutusnEwv1 .MuiButton-root,.aboutusnEwv1 .MuiButton-root.newclbtun { 
                                            font-size: 1.3vw !important;
                                        }
                                        .css-yg49xf-MuiToolbar-root {
                                            min-height: 5vw !important;
                                        }
                                        .aboutusnEwv1 {
                                            padding: 7vw 5.5vw !important;
                                        }
                                        .privCont001{min-width: 31vw !important;}
                                        .TermsPrivacy-content h2 { 
                                            font-size: 2.07vw !important;}

                                            .StillNeedbg label {
                                                font-size: 1.3vw;}

                                                .StillNeedbg input, .StillNeedbg select {
                                                    font-size: 1.2vw !important; 
                                                    height: 3.969vw;}
                                                    .StillNeedbg textarea {
                                                        font-size:  1.2vw !important; 
                                                        height: 8.969vw;
                                                    }
                                                    button.form-wizard-next-btn.btn.btn-primary-600.px-32.btnSubmitConts {
                                                        height: 4vw !important;  
                                                        font-size: 1.8vw !important;
                                                    }
                                                    .AddBtnAttachment {
                                                        font-size: 1.6vw; 
                                                        padding: 1.1vw 3vw;}
                                                        .aboutusnEwv1 .mobilebtnKstm svg {
                                                            fill: #000;
                                                        }
        }

        @media (max-width:991px){
            .aboutusnEwv1 .logotop {
                width: 10vw !important;
            }
        }



        @media (max-width:767px){
            .contactbgText { 
                flex-direction: column-reverse;
            }  
            .contactusImg,.contactLeftText { 
                width: 89vw;}
                .font24vw {
                    font-size: 2vw !important;
                }
                .StillNeedbg .font-2vw {
                    font-size: 3.5vw !important;
                }
                .StillNeedbg .font-1vw {
                    font-size: 2vw;
                }
                .StillNeedbg label {
                    font-size: 2.3vw;
                }
                .StillNeedbg input, .StillNeedbg select {
                    font-size: 2.2vw !important;
                    height: 5.969vw;
                }
                .StillNeedbg textarea{font-size: 2.2vw !important;}
                .AddBtnAttachment {
                    font-size: 2.6vw;}
                    button.form-wizard-next-btn.btn.btn-primary-600.px-32.btnSubmitConts {
                        height: 8vw !important;
                        font-size: 3.4vw !important;
                    }
                    .contactLeftText .text h4 {
                        padding: 0 0 1vw 0vw;
                        font-size: 2.34vw !important;
                    }
                    .contactLeftText .font08vw {
                        font-size: 2.1vw !important;
                    }
                    /* About us */
                    .HowItWorksbgNew {
                        grid-template-columns: repeat(2, 1fr); 
                    }
                     .HowItWorksbgNew .font24vw {
                        font-size: 3vw !important;
                    }
                    .HowItWorkstextNew .font08vw {
                        font-size: 2.3vw !important;
                    }
                    .HowItWorkstextNew .count {
                        width: 4.5vw; 
                        height: 4.5vw;  
                        font-size: 1.9vw; 
                        border-radius: 50%;
                    }
                    .ourvisiontextNew .font-2vw {
                        font-size: 2.5vw !important;
                    }
                    .OurmembersbgNew1 .font-1-25vw {
                        font-size: 2.25vw;
                    }
                    .Ourmemberstext {     gap: 5vw 1.88vw;
                        grid-template-columns: repeat(2, 1fr);}
                        .OurtextN1 span.img,.OurtextN1 span.img img {
                            display: inline-block;
                            width: 43.625vw;
                        }
                         .Ourmemberstext .font-1vw {
                            font-size: 2.5vw;
                        }
                        .aboutusTextNew .font04vw {
                            font-size: 3.34vw !important;
                        }
                        .aboutusnEwv1 {
                            padding: 11vw 5.5vw !important;
                        }

                        /* Terms Use Pape */
                        .Terms-privCont { 
                            flex-direction: column;
                        }
                        nav.sidebar-TermsPrivacy.position-sticky{display: none;}
                        .TermsPrivacy-content p, .TermsPrivacy-content ul li, .TermsPrivacy-content ol li, .TermsPrivacy-content a {
                            font-size: 1.8vw;
                            margin-bottom: 1vw;
                        }
                        .undtImg {
                            height: 40vw;
                        }

                        .paraHeadAccoding {
                            font-size: 2.7vw !important;
                        }
                        .paraHeadAccoding .count {
                            width: 4.83vw !important;
                            height: 4.83vw !important;
                            font-size: 2.3vw !important;
                        }

                        .iconStmnl { 
                            width: 2em !important;
                            height: 2em !important;}
                            .aboutusTextNew .font20vw {
                                font-size: 2.45vw !important;
                            }
                            .paraTextAccoding {
                                font-size: 2.2vw;
                            }

/* Home page  */
 .WhyJoinbg {
    flex-direction: column;
}
 .WhyJoinbg.reverseDv{   flex-direction: column-reverse;}
.WhyJoinbg .text {
 padding: 1vw !important;   width: 89vw;
}  
.WhyJoinbg .img, .WhyJoinbg .img img {
    width: 89vw;
}
.font48vw {
    font-size: 4.5vw !important;
}
.WhyJoinbg h3 {
    font-size: 3.5vw;
}
.WhyJoinbg p{font-size: 2.2vw;}

.imgTextbg h4 { 
    font-size: 2.65vw;
}
.imgTextbg p {
    color: #1E1E1E;
    font-size: 2.2vw;
}
.imgTextbg {
    height: 50vw;
    width: 43.7vw;
}
.imgTextbg button { 
    font-size: 2vw;
    border: none;
    padding: 1.7vw 4vw;}
    .font20vw {
        font-size: 2.45vw !important;
    }
    .textAccessimgbg {flex-direction: column;}
    .font04vw {
        font-size: 2.2vw !important;
    }
    .EliteFeaturesbg.d-flex.justify-content-between {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
    }
    .EliteFeaturesbg.d-flex.justify-content-between {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
    }
    .EliteFeatures {
        width: 42vw; padding: 4vw 3vw;
        height: 42vw;}

        .EliteFeatures .font08vw {
            font-size: 2vw !important;
        }
        .EliteFeatures .img img {
            width: 7vw;
        }
        .EliteFeatures .font24vw {
            font-size: 3vw !important;
        }

        .ApplyforMembership {
            flex-direction: column;
        }
        .ApplyforMembership .img,.ApplyforMembership .img img {
            width: 100vw;
        }
        .ApplyforMembership .text {
            width: 89vw;
        }
        .testimonialBgNew{flex-direction: column; gap: 3vw;}
        .whatOurMembers {
            width: 89vw;
        }
        .clintesliderbg {
            width: 89vw;
        }
        .clintesliderbg .clientReviewtext {
            color: #081726;
            font-size: 4.9vw;
            padding: 3vw 0 4vw 0;
        }
        .btnDslider {
            width: 9.3vw;
            height: 9.3vw;
        }
        .userDtails {
            font-size: 3vw;}
            .clintesliderbg .clientimg { 
                width: 6.855vw;
                height: 6.855vw;}
                .d-flex.align-items-center.btnDsliderbg {
                    justify-content: end;}
                    .ApplyforMembership .text .font-1-25vw {
                        font-size: 2.5vw;
                    }

                    .ApplyNowBntSt,.ApplyNowBntSt2 { 
                        font-size: 3vw;
                        padding: 1.2vw 3vw;}


                         .footerBgNew1 {
                            flex-direction: column;
                        }
                        .linksFooterNew ul { 
                            justify-content: space-between;
                        }
                        .linksFooterNew ul li a {
                            font-size: 2.5vw;
                        }
                        .copyrightNewone1.font08vw {
                            font-size: 2vw !important;
                            text-align: center;
                        }
                       .FooterNewlogobg {
                            flex-direction: column;
                        }
                        .FooterNewlogo img {
                            width: 15.021vw;
                        }

                        .footerTextNew {
                            width: 74%;
                            text-align: center;
                            margin-bottom: 3vw;         font-size: 3vw !important;
                        }
                
                        .MobileVewBtns{display: flex; justify-content: center; align-items: center; gap: 20px;}
        }

        @media (max-width:575px){
            .beforeNav {
                padding: 1vh 0 !important;
            }
            .font20vw {
                font-size: 3.5vw !important;
            }
            .aboutusnEwv1 .logotop {
                width: 18vw !important;
            }
            .aboutusnEwv1 {
                padding: 20vw 5.5vw 5.5vw 5.5vw !important;
            }
            /* about us */
            .aboutusTextNew {
                width: 89vw;
            }
            .font48vw {
                font-size: 5.9vw !important;
            }
            .font24vw {
                font-size: 3.5vw !important;
            }
            .HowItWorkstextNew .font08vw {
                font-size: 2.9vw !important;
            }
            .HowItWorksbgNew .font24vw {
                font-size: 4vw !important;
            }
            .HowItWorkstextNew .count {
                min-width: 8.5vw;
                max-width: 8.5vw;
                height: 8.5vw;
                font-size: 2.9vw;
                border-radius: 50%;
                margin-right: 2vw;
            }
            .ourvisiontextNew .font-2vw {
                font-size: 3vw !important;
            }
            .OurmembersbgNew1 .font-1-25vw {
                font-size: 3.5vw;
            }
            .Ourmemberstext .font-1vw {
                font-size: 3.5vw;
            }

            /* Privacy Terms */
            .hdtoptermpriv .font08vw {
                font-size: 3.4vw !important;
            }
            .TermsPrivacy-content h2 {
                font-size: 4.07vw !important;  
            }
            .TermsPrivacy-content p,.TermsPrivacy-content ul{margin-bottom: 5vw;}
            .TermsPrivacy-content p, .TermsPrivacy-content ul li, .TermsPrivacy-content ol li, .TermsPrivacy-content a {
                font-size: 3.5vw;
                
            }
            .paraHeadAccoding {
                font-size: 3.7vw !important;
            }
            .paraHeadAccoding .count {
                min-width: 7.83vw !important;
                max-width: 7.83vw !important;
                height: 7.83vw !important;
                font-size: 3.3vw !important;
            }
            .paraTextAccoding {
                font-size: 3.5vw;
            }
            .aboutusnEwv1 .MuiButtonBase-root {
                min-height: 43px;
            } 
            .aboutusTextNew .font20vw {
                font-size: 3.45vw !important;
            }
            .contactLeftText .text h4 {
                padding: 0 0 1vw 0vw;
                font-size: 3.5vw !important;
            }
            .contactLeftText .font08vw {
                font-size: 3.1vw !important;
            } 
            
            .StillNeedbg .font-2vw {
                font-size: 5.5vw !important;
            }
            .StillNeedbg .font-1vw {
                font-size: 3.5vw;
            }
            .StillNeedbg label {
                font-size: 4vw;
                margin-bottom: 1vw;
            }
            .StillNeedbg input, .StillNeedbg select {
                font-size: 4.2vw !important;
                height: 11.969vw;
                padding: 0 2.8vw;
            }
            .StillNeedbg textarea {
                font-size: 4.2vw !important;
                height: 30.969vw;
            }
            .AddBtnAttachment {
                font-size: 4.2vw;
                padding: 2.1vw 3vw;
            }
            button.form-wizard-next-btn.btn.btn-primary-600.px-32.btnSubmitConts {
                height: 12vw !important;
                font-size: 4.2vw !important;
            }
            .linksFooterNew ul li a {
                font-size: 3vw;
            }
            .copyrightNewone1.font08vw {
                font-size: 3vw !important;}
                .planNewSetup {
                    width: 89vw;}   
                    .MobileVewBtns button {font-size: 15px;
                        border: 1px solid #ccc;
                        padding: 8px 14px;}    
                        .MobileVewBtns{gap: 10px;}  
                        .subscription-container ul li svg {
                            position: relative;
                            top: 3px;
                        }  
                        
                        .bannerImgNw001{height: 100vw !important;}

                        .position-absolute.text-white.text-start.bnrfontsizeSet {
                            width: 100vw;
                            text-align: center !important;
                        }
                        .bnrfontsizeSet h1 {
                            font-size: 7.35vw;  text-align: center !important;
                        }
                        .bnrfontsizeSet p {
                            font-size: 3.54vw;  text-align: center !important;
                        }
                        .btnSetWth {
                            font-size: 3.7vw;
                            padding: 2.7vw 11.5vw; 
                        }
                        .WhyJoinbg h3 {
                            font-size: 5.5vw; text-align: center;
                        }
                        .WhyJoinbg p {text-align: center;
                            font-size: 3.5vw;
                        }
                        .WhyJoinbg {
                            border-bottom: 1px solid #ccc;
                            margin-bottom: 4vw;
                        }
                        .WhyJoinbg001  .WhyJoinbg:last-child{margin-bottom: 0vw;}
                        .textAccessimg img {
                            width: 10.5vw;
                        }
                        .textAccessimg .font24vw {
                            font-size:4.5vw !important;
                        }
                         .textAccessimg .font04vw {
                            font-size:3.2vw !important;
                        }
                        .MembershipCriteria h2{text-align: center !important;}
                         .MembershipCriteria p.font04vw {
                            font-size: 3.5vw !important;
                        }
                       .MembershipCriteriabg {
                            flex-direction: column;
                            text-align: center;
                        }
                        .ApplyNowBntSt, .ApplyNowBntSt2 {
                            font-size: 4vw;
                            padding: 3.2vw 6vw;
                        }.whatOurMembers h2.font20vw {
                            font-size: 3.45vw !important;
                        }
                        .whatOurMembers h2,.whatOurMembers p{text-align: center !important;}

                        .EliteFeatures {
                            width: 89vw;
                            padding: 4vw 3vw;
                            height: auto;
                            border-radius: 2vw;
                        }
                        .EliteFeaturesbg.d-flex.justify-content-between { 
                            grid-template-columns: repeat(1, 1fr);
                        }
                        .EliteFeatures .font08vw {
                            font-size: 3.5vw !important;
                        }
                        .EliteFeatures .font24vw {
                            font-size: 4vw !important;
                            padding: 2.7vw 0 1.7vw 0;
                        }
                        .whatOurMembers p.font20vw {
                            font-size: 3.45vw !important;
                        }
                        .Requirementstext{text-align: left;}
                        .yournetworkbg .font64vw {
                            font-size: 6.36vw !important;
                        }
                        .ApplyforMembership .text .font-1-25vw {
                            font-size: 3.5vw;
                            text-align: center;
                        }
                        .ApplyforMembership {
                            flex-direction: column;
                            text-align: center;
                        } 
        }
        /* Home responsive Css End */